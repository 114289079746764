import { selectors, store } from '@features/upload/store'
import { Alert, IconButton, Snackbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { getTestId } from '@shared/testing'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import React, { useCallback } from 'react'

export const Toast = () => {
    const dispatch = useAppDispatch()
    const isOpen = useAppSelector(selectors.isNotificationOpen)
    const type = useAppSelector(selectors.getNotificationType)
    const onClose = useCallback(() => {
        dispatch(store.toastSlice.actions.toggleOpen(false))
        dispatch(store.toastSlice.actions.toggleType(null))
    }, [dispatch])

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={5000}
            sx={{
                left: 'calc(50% - 140px) !important',
                transform: 'translate(-50%, 0)',
                bottom: '20px !important',
            }}
            onClose={onClose}
        >
            {!!type ? (
                <Alert
                    {...getTestId('upload-error')}
                    severity="error"
                    sx={{
                        bgcolor: '#EB5757',
                        p: '12px 20px 8px 16px',
                        borderRadius: '14px',
                        '& svg': { color: 'white' },
                        '& .MuiAlert-message': {
                            pt: '7px',
                        },
                        '& .MuiAlert-action': {
                            pt: '3px',
                        },
                    }}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: '0 0.5', bgcolor: 'transparent' }}
                            onClick={onClose}
                        >
                            <CloseIcon sx={{ width: '.8em', height: '.8em' }} />
                        </IconButton>
                    }
                >
                    <Typography variant="h2">Too big image, please upload another one</Typography>
                    {type === 'uploadError' && (
                        <Typography variant="subtitle2">Please ensure your image is no more than 30MP</Typography>
                    )}
                </Alert>
            ) : undefined}
        </Snackbar>
    )
}
