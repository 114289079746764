import { Box, InputProps } from '@mui/material'
import { SUPPORTED_IMAGE_FORMATS } from '@shared/constants'
import { IDs } from '@shared/testIds'
import { getTestId } from '@shared/testing'
import React from 'react'

const acceptImage = SUPPORTED_IMAGE_FORMATS.map(format => `image/${format}`).join(', ')

type Props = {
    id: string
    testId?: string
}

export const FileUpload = React.forwardRef<HTMLInputElement, InputProps & { component?: React.ElementType } & Props>(
    function FileUpload({ id, testId = IDs.main.upload.input, sx, ...rest }, ref) {
        return (
            <Box
                ref={ref}
                sx={[
                    {
                        display: 'none',
                    },
                    ...(Array.isArray(sx) ? sx : [sx]),
                ]}
                component={'input'}
                type="file"
                accept={acceptImage}
                id={id}
                {...getTestId(testId)}
                {...rest}
            />
        )
    },
)
