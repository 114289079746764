import React, { useEffect } from 'react'
import { Typography, Box, keyframes } from '@mui/material'
import { useAppSelector, useAppDispatch } from '@store/hooks'
import { selectors } from '@features/renderer/store'
import { store } from '@store/store.ui'
import { getLoaderLuminar } from '@store/selectors.ui'
import scssVars from '@styles/vars.module.scss'
import { theme } from '@shared/components/providers'
import { getTestId } from '@shared/testing'
import { IDs } from '@shared/testIds'

const animateGlow = keyframes`
  0% {
    background-position: 0 50%;
  }
  100% {
    background-position: 200% 50%;
  }
`

export function LoaderLuminar() {
    const isLoadingLoaderLuminar = useAppSelector(getLoaderLuminar)
    const fileAdded = useAppSelector(selectors.isImageInfoFetched)
    const dispatch = useAppDispatch()

    useEffect(() => {
        setTimeout(function () {
            dispatch(store.actions.hideLoaderLuminar(false))
        }, 4000)
    }, [dispatch])

    return (
        <Box
            {...getTestId(IDs.main.loader)}
            sx={[
                {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: scssVars.customPrimaryBackDarkColor,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    transition: `width 600ms ${theme.transitions.easing.easeOut}, background 400ms ${theme.transitions.easing.easeOut}`,
                },
                !isLoadingLoaderLuminar && {
                    width: 'calc(100% - 304px)',
                    background: 'transparent',
                },
                fileAdded && {
                    display: 'none',
                },
            ]}
        >
            <Typography
                align="center"
                sx={[
                    {
                        fontSize: '42px',
                        lineHeight: 1.5,
                        fontWeight: 700,
                        color: scssVars.accentColorPrimary,
                        opacity: 0,
                        transform: 'scale(0.5)',
                        transition: theme.transitions.create('all', { duration: 350 }),
                    },
                    isLoadingLoaderLuminar && {
                        transform: 'scale(1)',
                        opacity: 1,
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        backgroundImage:
                            'linear-gradient(90deg, #3192d8 0%,#8a4ecc 18%,#bb3196 42%,#e95a4d 63%,#ff7425 85%,#3192d8 100%)',
                        backgroundSize: '200% 200%',
                        animation: `${animateGlow} 2s linear infinite`,
                    },
                ]}
            >
                The most simple and easy photo editor for your pictures
            </Typography>
        </Box>
    )
}
