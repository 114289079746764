import { selectors, store, actions } from '@features/auth/store'
import { IDs } from '@features/auth/testIds'
import { useForm } from '@features/toolsPanel/hooks/form'
import { ErrorOutlineRounded } from '@mui/icons-material'
import { Box, Button, InputAdornment, Typography } from '@mui/material'
import { Input } from '@shared/components/input'
import { getTestId } from '@shared/testing'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import React, { useCallback, useEffect } from 'react'
import { FieldValues } from 'react-hook-form'
import { ErrorMessagesList, ErrorMessage } from '../ErrorMessages'
import { Loader } from '../Loader'
import { useServices } from '@shared/components/providers/services'

export const ForgotPassword = () => {
    const { onSubmit, emailProps, isServerError, formErrors, isFetching, backToSignIn } = useForgotPassword()
    const { analytics } = useServices()

    useEffect(() => {
        // ignore analytics event for testing
        if (process.env.NODE_ENV !== 'test') {
            analytics.showPopupAuthUsage('forgot_password_popup_show')
        }
    }, [])

    return (
        <Box
            sx={{
                m: '0 auto',
                position: 'relative',
                width: '392px',
                background: 'rgba(55, 55, 55, 0.4)',
                backdropFilter: 'blur(72px)',
                borderRadius: theme => `${(theme.shape.borderRadius as number) * 2}px`,
            }}
        >
            <Box
                {...getTestId(IDs.forgotPassword.form)}
                component={'form'}
                onSubmit={onSubmit}
                sx={{
                    display: 'flex',
                    minHeight: '448px',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '40px 40px 24px',
                    height: '100%',
                }}
            >
                <Box sx={{ flex: '2 0 auto' }}>
                    <Typography sx={{ mb: '8px', font: '700 28px/40px Inter' }} variant="h1">
                        Forgot Password?
                    </Typography>
                    <Typography sx={{ mb: '27px', color: newTextColor }} variant="h3">
                        Enter the email address you used when you joined and we`ll send you instructions to reset your
                        password
                    </Typography>
                    <Input fullWidth id="email" placeholder="Email address" {...emailProps} />
                    <ErrorMessagesList errors={formErrors} type="email" />
                    {isServerError && <ErrorMessage id="server-error">Email not found</ErrorMessage>}
                </Box>
                <Box sx={{ flex: '0 1 auto' }}>
                    <Button type="submit" color="secondary" fullWidth size={'large'} sx={{ mb: '16px' }}>
                        {isFetching ? <Loader /> : 'Reset Passowrd'}
                    </Button>
                    <Button onClick={backToSignIn} type="button" variant="text" fullWidth size={'medium'}>
                        Back to Sign In
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

const useForgotPassword = () => {
    const { form } = useForm({ mode: 'onChange' })
    const dispatch = useAppDispatch()
    const isServerError = useAppSelector(selectors.isResetPasswordError)
    const isFetching = useAppSelector(selectors.isResetPasswordProcessing)
    const { analytics } = useServices()

    useEffect(() => {
        const sub = form.watch(() => {
            const { isDirty } = form.formState
            if (isDirty && isServerError) {
                dispatch(store.authState.actions.resetActions('resetPassword'))
                form.clearErrors()
            }
        })
        return () => {
            sub.unsubscribe()
        }
    }, [dispatch, form, isServerError])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => form.clearErrors(), [])

    useEffect(() => {
        if (isServerError) {
            form.setError('email', {})
        }
    }, [isServerError, form])

    const onSubmit = useCallback(
        (values: FieldValues) => {
            if (isFetching) return
            dispatch(actions.resetPassword.act(values as { email: string }))

            // ignore analytics event for testing
            if (process.env.NODE_ENV !== 'test') {
                analytics.forgotPasswordSubmitUsage()
            }
        },
        [analytics, dispatch, isFetching],
    )

    return {
        onSubmit: form.handleSubmit(onSubmit),
        backToSignIn: () => {
            if (!isFetching) {
                dispatch(store.authUIState.actions.changeAuth('login'))

                // ignore analytics event for testing
                if (process.env.NODE_ENV !== 'test') {
                    analytics.forgotPasswordSwitchToLoginUsage()
                }
            }
        },
        isFetching,
        isServerError,
        emailProps: {
            error: !!form.formState.errors?.email,
            ...form.register('email', {
                required: { value: true, message: 'Email address is required' },
                pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Entered value does not match email format',
                },
            }),
            endAdornment: form.formState.errors?.email ? (
                <InputAdornment position="end">
                    <ErrorOutlineRounded sx={{ color: 'accent.fourth' }} fontSize="small" />
                </InputAdornment>
            ) : null,
        },
        formErrors: form.formState.errors,
    }
}

const newTextColor = 'rgba(255, 255, 255, 0.7)'
