import React from 'react'
import { Box, Typography, Link } from '@mui/material'
import { LumIcon } from '@shared/components/icons'
import { useAppSelector } from '@store/hooks'
import { isImageInfoFetched } from '@features/renderer/store/selectors'
import { getTestId } from '@shared/testing'
import { IDs } from '@shared/testIds'
import { useServices } from '@shared/components/providers/services'

export const BannerTryLuminar = () => {
    const fileAdded = useAppSelector(isImageInfoFetched)
    const { analytics } = useServices()

    return (
        <Box
            sx={[
                {
                    background: 'rgba(255, 255, 255, 0.16)',
                    borderRadius: '8px',
                    p: '12px',
                    position: 'absolute',
                    bottom: '0',
                    left: '8px',
                    display: 'flex',
                    width: 'calc(100% - 8px)',
                    backdropFilter: 'blur(27px)',
                    WebkitBackdropFilter: 'blur(27px)',
                    transform: 'translateX(200%)',
                    opacity: 0,
                    transition: theme => theme.transitions.create('transform', { duration: 400 }),
                    transitionDelay: '1s',
                },
                fileAdded && {
                    transform: 'translateX(0)',
                    opacity: 1,
                },
            ]}
            {...getTestId(IDs.banners.tryLuminarNeo.block)}
        >
            <Link
                href="https://skylum.com/luminar-neo-vs-online-photo-editor"
                target="_blank"
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    display: 'inline-block',
                    zIndex: 1,
                }}
                {...getTestId(IDs.banners.tryLuminarNeo.btn)}
                onClick={() => analytics.bannersUsage('tools_block')}
            />
            <img src="/images/luminar_neo_logo.png" style={{ width: '40px', marginRight: '12px' }} alt="Luminar Neo" />
            <Typography variant="h3" sx={{ fontSize: '13px' }}>
                Try a powerful desktop <br /> photo editor
            </Typography>
            <LumIcon
                as="arrow_up"
                size={'.7rem'}
                rotate={'90deg'}
                sx={{
                    ml: 'auto',
                    mr: '5px',
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    align: 'center',
                    justifyContent: 'center',
                }}
            />
        </Box>
    )
}
