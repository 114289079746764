import { Google, Apple } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { LumIcon } from '@shared/components/icons'
import React from 'react'
import { useServices } from '@shared/components/providers/services'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { getType } from '@features/auth/store/selectors.ui'
import { authState, authUIState } from '@features/auth/store/store'

export const SocialAuth = () => {
    const { analytics } = useServices()
    const dispatch = useAppDispatch()
    const authPopupType = useAppSelector(getType)

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <SocialButton
                onClick={() => {
                    // ignore analytics event for testing
                    if (process.env.NODE_ENV !== 'test') {
                        dispatch(authState.actions.changeSocOrEmailType('facebook'))
                    }

                    popupCenter('/signin/facebook', 'Facebook Sign In', {
                        width: 750,
                        height: DEFAULT_SIZE.height,
                    })

                    // ignore analytics event for testing
                    if (process.env.NODE_ENV !== 'test') {
                        if (authPopupType === 'login') {
                            analytics.signInWithSocialUsage('facebook')
                        } else {
                            analytics.signUpWithSocialUsage('facebook')
                        }
                        dispatch(authUIState.actions.setStartSocProcess(true))
                    }
                }}
            >
                <LumIcon
                    as="facebookAuth"
                    viewBox="0 0 13 23"
                    sx={{ width: '13px', height: '23px', color: 'currentColor' }}
                />
            </SocialButton>
            <SocialButton
                onClick={() => {
                    // ignore analytics event for testing
                    if (process.env.NODE_ENV !== 'test') {
                        dispatch(authState.actions.changeSocOrEmailType('google'))
                    }

                    popupCenter('/signin/google', 'Google Sign In')

                    // ignore analytics event for testing
                    if (process.env.NODE_ENV !== 'test') {
                        if (authPopupType === 'login') {
                            analytics.signInWithSocialUsage('google')
                        } else {
                            analytics.signUpWithSocialUsage('google')
                        }
                        dispatch(authUIState.actions.setStartSocProcess(true))
                    }
                }}
            >
                <Google sx={{ width: '24px', height: '24px' }} />
            </SocialButton>
            <SocialButton
                onClick={() => {
                    // ignore analytics event for testing
                    if (process.env.NODE_ENV !== 'test') {
                        dispatch(authState.actions.changeSocOrEmailType('microsoft'))
                    }

                    popupCenter('/signin/microsoft', 'Azure Sign In')

                    // ignore analytics event for testing
                    if (process.env.NODE_ENV !== 'test') {
                        if (authPopupType === 'login') {
                            analytics.signInWithSocialUsage('microsoft')
                        } else {
                            analytics.signUpWithSocialUsage('microsoft')
                        }
                        dispatch(authUIState.actions.setStartSocProcess(true))
                    }
                }}
            >
                <LumIcon
                    as="googleAuth"
                    viewBox="0 0 32 32"
                    sx={{ width: '2em', height: '2em', color: 'currentColor' }}
                />
            </SocialButton>
            <SocialButton
                onClick={() => {
                    // ignore analytics event for testing
                    if (process.env.NODE_ENV !== 'test') {
                        dispatch(authState.actions.changeSocOrEmailType('apple'))
                    }

                    popupCenter('/signin/apple', 'Apple Sign In')

                    // ignore analytics event for testing
                    if (process.env.NODE_ENV !== 'test') {
                        if (authPopupType === 'login') {
                            analytics.signInWithSocialUsage('apple')
                        } else {
                            analytics.signUpWithSocialUsage('apple')
                        }
                        dispatch(authUIState.actions.setStartSocProcess(true))
                    }
                }}
            >
                <Apple />
            </SocialButton>
        </Box>
    )
}

const DEFAULT_SIZE = { width: 500, height: 650 }
const popupCenter = (url: string, title: string, options = DEFAULT_SIZE) => {
    const dualScreenLeft = window.screenLeft ?? window.screenX
    const dualScreenTop = window.screenTop ?? window.screenY

    const width = window.innerWidth ?? document.documentElement.clientWidth ?? screen.width

    const height = window.innerHeight ?? document.documentElement.clientHeight ?? screen.height

    const systemZoom = width / window.screen.availWidth

    const left = (width - options.width) / 2 / systemZoom + dualScreenLeft
    const top = (height - options.height) / 2 / systemZoom + dualScreenTop

    const newWindow = window.open(url, title, `width=${options.width},height=${options.height},top=${top},left=${left}`)

    newWindow?.focus()
}

const SocialButton = ({ children, onClick }: { children: React.ReactNode; onClick?: () => void }) => (
    <Button
        type="button"
        onClick={onClick}
        sx={{
            width: '72px',
            height: '48px',
            border: theme => `1px solid ${theme.palette.text.secondary}`,
            borderRadius: theme => `${Number(theme.shape.borderRadius)}px`,
            bgcolor: 'transparent',
            color: theme => theme.palette.text.primary,
            '&:hover,&:active': {
                border: '1px solid transparent',
            },
        }}
    >
        {children}
    </Button>
)
