import { Box, Dialog as MuiDialog, SxProps, Theme } from '@mui/material'
import React, { useCallback } from 'react'
import { useAppSelector } from '@store/hooks'
import { useDispatch } from 'react-redux'
import { uploadPopupStatus, getPopupType } from '@features/upload/store/selectors'
import { store } from '@features/upload/store'
import { Children } from './children'

const { toggleOpen, toggleType } = store.slice.actions

export const Dialog = () => {
    const open = useAppSelector(uploadPopupStatus)
    const dispatch = useDispatch()
    const type = useAppSelector(getPopupType)
    const onClose = useCallback(() => {
        dispatch(toggleOpen(false))
        dispatch(toggleType('replace'))
    }, [dispatch])

    return (
        <MuiDialog sx={DialogStyles} onClose={onClose} open={open} keepMounted={false}>
            <Box
                sx={{
                    m: '0 auto',
                    position: 'relative',
                    width: '276px',
                    background: 'rgba(55, 55, 55, 0.4)',
                    backdropFilter: 'blur(72px)',
                    borderRadius: theme => `${(theme.shape.borderRadius as number) * 2}px`,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        padding: '24px',
                        height: '100%',
                        textAlign: 'center',
                    }}
                >
                    <Children type={type} />
                </Box>
            </Box>
        </MuiDialog>
    )
}

const DialogStyles: SxProps<Theme> = {
    '.MuiDialog-container': {
        backdropFilter: 'blur(7px)',
        background: 'rgba(0, 0, 0, 0.5)',
        '& .MuiPaper-root': {
            backgroundColor: 'transparent',
            backgroundImage: 'none',
            border: '1px solid rgba(255, 255, 255, 0.24)',
            borderRadius: '16px',
        },
    },
}
