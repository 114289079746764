import { selectors, store, actions } from '@features/auth/store'
import { useForm } from '@features/toolsPanel/hooks/form'
import { ErrorOutlineRounded } from '@mui/icons-material'
import { Box, Button, InputAdornment, Link, Typography } from '@mui/material'
import { Input } from '@shared/components/input'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { throttle } from 'lodash-es'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FieldValues } from 'react-hook-form'
import { ErrorMessage, ErrorMessagesList } from '../ErrorMessages'
import { Loader } from '../Loader'
import { Password } from '../Password'
import {
    PasswordCheckPayload,
    passwordConditionsAreSatisfied,
    passwordRules,
    PasswordStrengthBlock,
} from '../PasswordStrengthBlock'
import { useServices } from '@shared/components/providers/services'
import { authState } from '@features/auth/store/store'

export const Registration = () => {
    const {
        onSubmit,
        emailProps,
        isServerError,
        formErrors,
        passwordProps,
        switchToLogin,
        switchToChoose,
        isFetching,
        passwordList,
        registerErrorType,
    } = useRegisrtationForm()
    const { analytics } = useServices()

    useEffect(() => {
        // ignore analytics event for testing
        if (process.env.NODE_ENV !== 'test') {
            analytics.showPopupAuthUsage('sign_up_email_popup_show')
        }
    }, [])

    const getServerErrors = () =>
        registerErrorType === 'user_exists' ? (
            <ErrorMessage id="user_exists">
                {'An Account with this adress already exists.'}{' '}
                <Link href="#" onClick={switchToLogin}>
                    Sign In
                </Link>
            </ErrorMessage>
        ) : (
            <ErrorMessage id="unknown">{'Something went wrong'}</ErrorMessage>
        )
    return (
        <Box
            sx={{
                m: '0 auto',
                position: 'relative',
                width: '392px',
                background: 'rgba(55, 55, 55, 0.4)',
                backdropFilter: 'blur(72px)',
                borderRadius: theme => `${(theme.shape.borderRadius as number) * 2}px`,
                height: '100%',
            }}
        >
            <Box
                component={'form'}
                onSubmit={onSubmit}
                autoComplete={'off'}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '40px 40px 24px',
                    height: '100%',
                }}
            >
                <Box sx={{ flex: '2 0 auto' }}>
                    <Typography sx={{ mb: '8px', font: '700 28px/40px Inter' }} variant="h1">
                        Create an Account
                    </Typography>
                    <Typography sx={{ mb: '27px', color: newTextColor }} variant="h3">
                        Already have an account?
                        <br />
                        <Link href="#" component={'span'} onClick={switchToLogin}>
                            Sign In
                        </Link>{' '}
                        or{' '}
                        <Link href="#" component={'span'} onClick={switchToChoose}>
                            Back to Previous Step
                        </Link>
                    </Typography>
                    <Typography sx={{ mb: '12px', font: '600 17px/24px Inter' }} variant="h2">
                        Sign Up with email
                    </Typography>
                    {/* Hack for breaking chrome auto-fill */}
                    <input
                        type="password"
                        name="fake-password"
                        autoComplete="new-password"
                        tabIndex={-1}
                        style={{ opacity: 0, float: 'left', border: 'none', height: 0, width: 0 }}
                    />
                    <Input fullWidth autoComplete="false" id="email" placeholder="Email address" {...emailProps} />
                    <ErrorMessagesList
                        errors={formErrors}
                        type="email"
                        map={{
                            noUser: (
                                <>
                                    {'An Account with this adress already exists.'}{' '}
                                    <Link href="#" onClick={switchToLogin}>
                                        Sign In
                                    </Link>
                                </>
                            ),
                        }}
                    />
                    <Password
                        fullWidth
                        autoComplete="new-password"
                        id="password"
                        placeholder="Password"
                        {...passwordProps}
                    />
                    <ErrorMessagesList errors={formErrors} type="password" />
                    {isServerError ? getServerErrors() : null}
                    <PasswordStrengthBlock checked={passwordList} />
                </Box>
                <Box sx={{ flex: '0 1 auto' }}>
                    <Button type="submit" color="secondary" fullWidth size={'large'} sx={{ mb: '20px' }}>
                        {isFetching ? <Loader /> : 'Create'}
                    </Button>
                    <Typography variant="subtitle2" align="center" color={newTextColor}>
                        By clicking Create, I agree that I have read and accepted the{' '}
                        <Link href="https://skylum.com/terms-of-use">Terms of Use</Link> and{' '}
                        <Link href="https://skylum.com/legal#privacy-policy">Privacy Policy</Link>
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

const useRegisrtationForm = () => {
    const { form } = useForm({ mode: 'onChange' })
    const dispatch = useAppDispatch()
    const [passCheck, setCheck] = useState<PasswordCheckPayload>({})
    const isServerError = useAppSelector(selectors.isRegisterError)
    const registerErrorType = useAppSelector(selectors.getRegisterErrorType)
    const isEmailError = useAppSelector(selectors.isEmailError)
    const passwordState = useRef<boolean>(false)
    const isFetching = useAppSelector(selectors.isRegisteringProcessing)
    const { analytics } = useServices()

    const updateInfo = useCallback(() => {
        const values = form.getValues()
        const blurred = passwordState.current
        const result = passwordRules.reduce((acc, { id, validate }) => {
            const valid = validate(values.password, values)
            return {
                ...acc,
                [id]: !valid && blurred ? 'error' : !valid && !blurred ? 'idle' : 'passed',
            }
        }, {})
        setCheck(result)
    }, [form])

    const passwordField = form.register('password', {
        required: { value: true, message: 'Password is required' },
        pattern: {
            value: /^[^ ]+$/,
            message: 'Password should`t contain spaces',
        },
        onBlur: () => {
            passwordState.current = true
            updateInfo()
        },
        onChange: () => {
            updateInfo()
        },
    })
    const emailField = form.register('email', {
        required: { value: true, message: 'Email address is required' },
        pattern: {
            value: /\S+@\S+\.\S+/,
            message: 'Entered value does not match email format',
        },
        onBlur: () => {
            checkEmail()
        },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const checkEmail = useCallback(
        throttle(
            () => {
                const { isTouched, error } = form.getFieldState('email')
                const value = form.getValues().email
                if ((isTouched || !!value) && !error) {
                    dispatch(actions.checkEmail.act({ email: value }))
                }
            },
            100,
            { trailing: true },
        ),
        [form, dispatch],
    )

    useEffect(() => {
        if (isEmailError) {
            form.setError('email', { type: 'noUser' })
        }
    }, [isEmailError, form])

    useEffect(() => {
        const sub = form.watch(() => {
            const { isDirty } = form.formState
            if (isDirty && isServerError) {
                dispatch(store.authState.actions.resetActions('register'))
                form.clearErrors(isEmailError ? 'password' : [])
            }
        })
        return () => {
            sub.unsubscribe()
        }
    }, [dispatch, form, isServerError, isEmailError])

    useEffect(() => {
        if (isServerError) {
            form.setError('email', {})
            form.setError('password', {})
        }
    }, [isServerError, form])

    const onSubmit = useCallback(
        (values: FieldValues) => {
            if (!passwordConditionsAreSatisfied(passCheck) || isFetching) return
            // ignore analytics event for testing
            if (process.env.NODE_ENV !== 'test') {
                dispatch(authState.actions.changeSocOrEmailType('email'))
            }

            dispatch(actions.register.act(values as { email: string; password: string }))

            // ignore analytics event for testing
            if (process.env.NODE_ENV !== 'test') {
                analytics.signUpSubmitUsage()
            }
        },
        [analytics, dispatch, isFetching, passCheck],
    )

    return {
        onSubmit: form.handleSubmit(onSubmit),
        switchToLogin: () => {
            dispatch(store.authUIState.actions.changeAuth('login'))

            // ignore analytics event for testing
            if (process.env.NODE_ENV !== 'test') {
                analytics.signUpEmailSwitchToLoginUsage()
            }
        },
        switchToChoose: () => {
            dispatch(store.authUIState.actions.changeAuth('registrationChoose'))

            // ignore analytics event for testing
            if (process.env.NODE_ENV !== 'test') {
                analytics.signUpEmailSwitchToSignUpSocialUsage()
            }
        },
        isFetching,
        isServerError,
        emailProps: {
            error: !!form.formState.errors?.email,
            ...emailField,
            endAdornment: form.formState.errors?.email ? (
                <InputAdornment position="end">
                    <ErrorOutlineRounded sx={{ color: 'accent.fourth' }} fontSize="small" />
                </InputAdornment>
            ) : null,
        },
        passwordProps: {
            error: !!form.formState.errors?.password,
            ...passwordField,
            endAdornment: form.formState.errors?.password ? (
                <InputAdornment position="end">
                    <ErrorOutlineRounded sx={{ color: 'accent.fourth' }} fontSize="small" />
                </InputAdornment>
            ) : null,
        },
        passwordList: passCheck,
        formIsValid: form.formState.isValid,
        formErrors: form.formState.errors,
        registerErrorType,
    }
}

const newTextColor = 'rgba(255, 255, 255, 0.7)'
