import React from 'react'
import { Grid } from '@mui/material'
import { NextRouter } from 'next/router'
import { useAppSelector } from '@store/hooks'
import { getLoaderLuminar } from '@store/selectors.ui'
import { theme } from '@shared/components/providers'
import { Center, Left, Right } from './Parts'

export type Payload = { router: NextRouter }
export type RComponent = null | (({ router }: Payload) => JSX.Element[] | JSX.Element | null | undefined)

export const Header = () => {
    const isLoadingLoaderLuminar = useAppSelector(getLoaderLuminar)

    return (
        <Grid
            container
            justifyContent={'space-between'}
            sx={[
                {
                    p: '14px 24px',
                    transform: 'translateY(-100%)',
                    transition: `${theme.transitions.create(['opacity', 'transform', 'width'], {
                        duration: 600,
                        easing: theme.transitions.easing.easeOut,
                    })}`,
                },
                !isLoadingLoaderLuminar && {
                    transform: 'translateY(0%)',
                },
            ]}
        >
            <Grid item>
                <Left />
            </Grid>
            <Grid item>
                <Center />
            </Grid>
            <Grid item sx={{ display: 'flex' }}>
                <Right />
            </Grid>
        </Grid>
    )
}
