import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { getTestId } from '@shared/testing'
import { IDs } from '@shared/testIds'
import { useServices } from '@shared/components/providers/services'

export const BannerTryLuminarBig = () => {
    const { analytics } = useServices()

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                background:
                    'linear-gradient(90deg, rgba(6, 207, 255, 0.3) 0%, rgba(111, 94, 248, 0.3) 52.19%, rgba(173, 60, 255, 0.3) 100%)',
                borderRadius: '14px',
                mt: '22px',
                width: '100%',
                maxWidth: '776px',
                p: '16px 20px',
            }}
            {...getTestId(IDs.banners.tryLuminarNeoBig.block)}
        >
            <Typography variant="body1" sx={{ fontSize: '15px', lineHeight: '26px' }}>
                Want to edit photos with even more tools
                <br />
                and AI power? Use our Luminar NEO photo editor.
            </Typography>
            <Button
                href="https://skylum.com/luminar-neo-vs-online-photo-editor"
                target="_blank"
                size="large"
                sx={{ p: '11px 22px' }}
                {...getTestId(IDs.banners.tryLuminarNeoBig.btn)}
                onClick={() => analytics.bannersUsage('upload_photo_block')}
            >
                Try it Now
            </Button>
        </Box>
    )
}
