import { uiSelectors, store } from '@features/auth/store'
import { AuthType } from '@features/auth/types'
import { Dialog as MuiDialog, SxProps, Theme } from '@mui/material'
import { useAppSelector } from '@store/hooks'
import React from 'react'
import { useDispatch } from 'react-redux'

type Props = {
    children: React.ReactNode
}

export const Dialog = ({ children }: Props) => {
    const open = useAppSelector(uiSelectors.open)
    const dialogType = useAppSelector(uiSelectors.getType)
    const dispatch = useDispatch()

    return (
        <MuiDialog
            sx={getDialogStyles(dialogType)}
            onClose={() => dispatch(store.authUIState.actions.setOpen(false))}
            open={open}
            keepMounted={false}
        >
            {children}
        </MuiDialog>
    )
}

const getDialogStyles = (type: AuthType): SxProps<Theme> => {
    return {
        '.MuiDialog-container': {
            backdropFilter: 'blur(7px)',
            background: 'rgba(0, 0, 0, 0.5)',
            '& .MuiPaper-root': {
                backgroundColor: 'transparent',
                backgroundImage: 'none',
                border: '1px solid rgba(255, 255, 255, 0.24)',
                borderRadius: '16px',
                ...(type === 'registration' || type === 'newPassword'
                    ? {
                          height: '100%',
                          maxHeight: '670px',
                      }
                    : {}),
            },
        },
    }
}
