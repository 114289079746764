import { ExportTypes } from '@features/export/types'
import { selectors as uiSelectors, actions as rendererActions } from '@features/renderer/store'
import { actions, store as exportStore } from '@features/export/store'
import { Types } from '@features/upload/store/store'
import { FileUpload } from '@shared/components/fileUpload'
import { Box, Avatar, Typography, Button } from '@mui/material'
import { LumIcon } from '@shared/components/icons'
import { useAppSelector } from '@store/hooks'
import React, { ChangeEvent, useCallback, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { store as uploadStore } from '../../store'
import { IDs } from '@features/upload/testIds'
import { useServices } from '@shared/components/providers/services'

type Props = {
    type: Types
}

const { toggleOpen, toggleType } = uploadStore.slice.actions

const text = {
    head: 'Current image has some edits',
    subhead: 'If you change the image you will lost all edits. Please save it before or replace without saving',
    btns: {
        jpeg: 'Save as JPEG',
        png: 'Save as PNG',
        replace: 'Replace Without Saving',
        upload: 'Upload New Image',
    },
}

const First = () => {
    const { toggleStatus, setDesiredType } = exportStore.slice.actions
    const dispatch = useDispatch()
    const fileUploadRef = useRef<HTMLInputElement>(null)
    const { analytics } = useServices()

    const onClose = useCallback(() => {
        dispatch(toggleOpen(false))
        dispatch(toggleType('replace'))
    }, [dispatch])

    const handleOpenDefaultWindow = useCallback(() => {
        if (fileUploadRef.current) {
            fileUploadRef.current.click()
            analytics.uploadNewImagePopupChangeImgUsage()
        }
    }, [analytics])

    const exportImage = (type: ExportTypes) => () => {
        dispatch(setDesiredType(type))
        dispatch(toggleStatus('pending'))
        dispatch(actions.handleExport())
        dispatch(toggleType('uploadOnly'))
    }

    const onFileUpload = useCallback(
        (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            const result = (event.target as HTMLInputElement).files
            if (result) {
                onClose()
                dispatch(rendererActions.uploadImage.act([...result]))
            }
        },
        [dispatch, onClose],
    )

    return (
        <CommonWrapper exportImage={exportImage}>
            <Button
                fullWidth
                size={'large'}
                sx={{
                    mb: '12px',
                    background: 'rgba(255, 255, 255, 0.04)',
                    font: '400 12px/16px Inter',
                    letterSpacing: '0.01em',
                    borderRadius: theme => `${theme.shape.borderRadius as number}px`,
                }}
                onClick={handleOpenDefaultWindow}
            >
                {text.btns.replace}
            </Button>
            <FileUpload ref={fileUploadRef} testId={IDs.fileInput} onChange={onFileUpload} id="icon-header-photo" />
        </CommonWrapper>
    )
}

const Second = () => {
    const { toggleStatus, setDesiredType } = exportStore.slice.actions
    const dispatch = useDispatch()
    const fileUploadRef = useRef<HTMLInputElement>(null)

    const onClose = useCallback(() => {
        dispatch(toggleOpen(false))
        dispatch(toggleType('replace'))
    }, [dispatch])

    const handleOpenDefaultWindow = useCallback(() => {
        if (fileUploadRef.current) {
            fileUploadRef.current.click()
        }
    }, [])

    const exportImage = (type: ExportTypes) => () => {
        dispatch(setDesiredType(type))
        dispatch(toggleStatus('pending'))
        dispatch(actions.handleExport())
    }

    const onFileUpload = useCallback(
        (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            const result = (event.target as HTMLInputElement).files
            if (result) {
                onClose()
                dispatch(rendererActions.uploadImage.act([...result]))
            }
        },
        [dispatch, onClose],
    )

    return (
        <CommonWrapper exportImage={exportImage}>
            <Button
                fullWidth
                size={'large'}
                sx={{
                    mb: '12px',
                    background: 'rgba(255, 255, 255, 0.04)',
                    font: '400 12px/16px Inter',
                    letterSpacing: '0.01em',
                    borderRadius: theme => `${theme.shape.borderRadius as number}px`,
                }}
                onClick={handleOpenDefaultWindow}
            >
                {text.btns.upload}
            </Button>
            <FileUpload ref={fileUploadRef} testId={IDs.fileInput} onChange={onFileUpload} id="icon-header-photo" />
        </CommonWrapper>
    )
}

type WrapperProps = {
    children: JSX.Element | JSX.Element[]
    exportImage: (types: ExportTypes) => () => void
}

const CommonWrapper = ({ children, exportImage }: WrapperProps) => {
    const url = useAppSelector(uiSelectors.getCurrentImageUrl)
    return (
        <Box sx={{ flex: '2 0 auto' }}>
            <Box
                sx={{
                    width: '82px',
                    height: '82px',
                    m: '0 auto 16px',
                    border: '2px solid rgba(255, 255, 255, 0.24)',
                    borderRadius: '50%',
                    position: 'relative',
                }}
            >
                <Avatar
                    sx={{
                        width: '100%',
                        height: '100%',
                        background: 'none',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            background: `url(${url}) no-repeat`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                        }}
                    />
                </Avatar>
                <LumIcon
                    viewBox="0 0 22 22"
                    as="attention"
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        fontSize: '22px',
                        zIndex: 1,
                    }}
                />
            </Box>
            <Typography sx={{ mb: '12px', font: '600 17px/24px Inter' }} variant="h2">
                {text.head}
            </Typography>
            <Typography
                sx={{
                    mb: '16px',
                    font: '400 12px/16px Inter',
                    color: 'text.secondary',
                    letterSpacing: '0.01em',
                }}
                variant="body1"
            >
                {text.subhead}
            </Typography>
            <Button
                fullWidth
                size={'large'}
                sx={{
                    mb: '8px',
                    background: 'rgba(255, 255, 255, 0.04)',
                    font: '400 12px/16px Inter',
                    letterSpacing: '0.01em',
                    borderRadius: theme => `${theme.shape.borderRadius as number}px`,
                }}
                onClick={exportImage('jpeg')}
            >
                {text.btns.jpeg}
            </Button>
            <Button
                fullWidth
                size={'large'}
                sx={{
                    mb: '12px',
                    background: 'rgba(255, 255, 255, 0.04)',
                    font: '400 12px/16px Inter',
                    letterSpacing: '0.01em',
                    borderRadius: theme => `${theme.shape.borderRadius as number}px`,
                }}
                onClick={exportImage('png')}
            >
                {text.btns.png}
            </Button>
            {children}
        </Box>
    )
}

const map: Record<Types, () => JSX.Element> = {
    replace: First,
    uploadOnly: Second,
}

export const Children = ({ type }: Props) => map?.[type]() ?? null
