import { OutlinedInput as MuiInput, InputProps, SxProps, Theme } from '@mui/material'
import React, { forwardRef } from 'react'

type Props = InputProps

export const Input = forwardRef<any, Props>(function Input({ sx, ...props }, ref) {
    return (
        <MuiInput
            ref={ref}
            {...props}
            sx={Array.isArray(sx) ? sx.concat(TextFieldStyles) : [{ ...TextFieldStyles, ...sx }]}
        />
    )
})

const TextFieldStyles: SxProps<Theme> = {
    mb: '10px',
    '&:before, &:after': {
        display: 'none',
    },
    '.MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(255, 255, 255, 0.4)',
        borderRadius: theme => `${theme.shape.borderRadius}px`,
        p: '9px 0 9px 14px',
        fontSize: 'h3.fontSize',
        color: 'text.primary',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
        border: theme => `1px solid ${theme.palette.text.primary}`,
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: theme => `1px solid ${theme.palette.accent.fourth}`,
    },
}
