import type { GetServerSideProps, NextPage } from 'next'
import { Grid } from '@mui/material'
import { Upload } from '@features/index'
import { Renderer } from '@features/renderer'
import { ToolsPanel } from '@features/toolsPanel'
import { Layout } from '@shared/components/layout'
import { basicAuthCheck } from '@shared/server/basicAuth'
import { BannerTryLuminar } from '@shared/components/bannerTryLuminar'
import { useSession } from 'next-auth/react'
import { useServices } from '@shared/components/providers/services'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { getStartSocProcess, getType } from '@features/auth/store/selectors.ui'
import { useEffect } from 'react'
import { getSocOrEmailType } from '@features/auth/store/selectors'
import { authUIState } from '@features/auth/store/store'

const TOP_TOOLBAR_HEIGHT = '56px'
const Home: NextPage = () => {
    const { data: session } = useSession()
    const dispatch = useAppDispatch()
    const { analytics } = useServices()
    const authPopupType = useAppSelector(getType)
    const startSocProcess = useAppSelector(getStartSocProcess)
    const socOrEmailType = useAppSelector(getSocOrEmailType)

    useEffect(() => {
        analytics.pageView()
        // ignore analytics event for testing
        if (process.env.NODE_ENV !== 'test') {
            if (session && startSocProcess) {
                if (authPopupType === 'login') {
                    analytics.signInWithSocialSuccessUsage(socOrEmailType)
                } else {
                    analytics.signUpWithSocialSuccessUsage(socOrEmailType)
                }
                dispatch(authUIState.actions.setStartSocProcess(false))
            }
        }
    }, [session, startSocProcess])

    return (
        <Layout>
            <Grid container spacing={1} sx={{ height: `calc(100vh - ${TOP_TOOLBAR_HEIGHT})`, p: '0 14px' }}>
                <Grid
                    item
                    sx={{
                        position: 'relative',
                        maxWidth: 'calc(100% - 280px)',
                        flexBasis: 'calc(100% - 280px)',
                    }}
                >
                    <Renderer />
                    <Upload />
                </Grid>
                <Grid item sx={{ maxWidth: '280px', flexBasis: '280px', position: 'relative' }}>
                    <ToolsPanel />
                    <BannerTryLuminar />
                </Grid>
            </Grid>
        </Layout>
    )
}

export default Home

export const getServerSideProps: GetServerSideProps = async ctx => {
    const { req, res } = ctx
    const { host } = req.headers

    if (['stage-web.skylum.com'].includes(host || '')) {
        await basicAuthCheck(req, res)
    }

    return {
        props: {},
    }
}
