export const IDs = {
    login: {
        form: 'login-form',
    },
    passwordResetCode: {
        form: 'reset-code-form',
    },
    updatePassword: {
        form: 'update-password-form',
    },
    forgotPassword: {
        form: 'forgot-password-form',
    },
} as const
