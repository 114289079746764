import { selectors, store, actions } from '@features/auth/store'
import { Box, Button, Link, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import React, { useEffect } from 'react'
import { useSession } from 'next-auth/react'
import { SocialAuth } from '../SocialAuth'
import { useServices } from '@shared/components/providers/services'

export const RegistrationOptions = () => {
    const { switchToLogin, switchToRegistration } = useRegistrationForm()
    const { analytics } = useServices()

    useEffect(() => {
        // ignore analytics event for testing
        if (process.env.NODE_ENV !== 'test') {
            analytics.showPopupAuthUsage('sign_up_popup_show')
        }
    }, [])

    return (
        <Box
            sx={{
                m: '0 auto',
                position: 'relative',
                width: '392px',
                background: 'rgba(55, 55, 55, 0.4)',
                backdropFilter: 'blur(72px)',
                borderRadius: theme => `${(theme.shape.borderRadius as number) * 2}px`,
                height: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '40px 40px 24px',
                    height: '100%',
                }}
            >
                <Box sx={{ flex: '2 0 auto' }}>
                    <Typography sx={{ mb: '8px', font: '700 28px/40px Inter' }} variant="h1">
                        Create an Account
                    </Typography>
                    <Typography sx={{ mb: '27px', color: newTextColor }} variant="h3">
                        Already have an account?{' '}
                        <Link href="#" component={'span'} onClick={switchToLogin}>
                            Sign In
                        </Link>
                    </Typography>
                    <Typography sx={{ mb: '12px', font: '600 17px/24px Inter' }} variant="h2">
                        Sign Up with
                    </Typography>
                    <SocialAuth />
                </Box>
                <Typography
                    variant="body1"
                    sx={{
                        position: 'relative',
                        textAlign: 'center',
                        padding: '34px 0',
                        color: newTextColor,
                        textTransform: 'uppercase',
                        '&:before, &:after': {
                            content: '""',
                            position: 'absolute',
                            width: '45%',
                            top: '50%',
                            left: 0,
                            display: 'block',
                            borderTop: `1px solid ${newTextColor}`,
                        },
                        '&:after': {
                            right: 0,
                            left: 'auto',
                        },
                    }}
                >
                    or
                </Typography>
                <Box sx={{ flex: '0 1 auto' }}>
                    <Button
                        type="button"
                        color="primary"
                        onClick={switchToRegistration}
                        fullWidth
                        size={'large'}
                        sx={{ mb: '16px' }}
                    >
                        Sign Up with email
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

const useRegistrationForm = () => {
    const dispatch = useAppDispatch()
    const isServerError = useAppSelector(selectors.isRegisterError)
    const registerErrorType = useAppSelector(selectors.getRegisterErrorType)
    const { status } = useSession()
    const { analytics } = useServices()

    useEffect(() => {
        if (status === 'authenticated') {
            dispatch(actions.fetchUser.act())
        }
    }, [dispatch, status])

    return {
        switchToLogin: () => {
            dispatch(store.authUIState.actions.changeAuth('login'))

            // ignore analytics event for testing
            if (process.env.NODE_ENV !== 'test') {
                analytics.signUpSwitchToLoginUsage()
            }
        },
        switchToRegistration: () => {
            dispatch(store.authUIState.actions.changeAuth('registration'))

            // ignore analytics event for testing
            if (process.env.NODE_ENV !== 'test') {
                analytics.signUpSwitchToSignUpEmailUsage()
            }
        },
        isServerError,
        registerErrorType,
    }
}

const newTextColor = 'rgba(255, 255, 255, 0.7)'
