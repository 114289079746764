import React from 'react'
import { Typography, Box, keyframes } from '@mui/material'
import { LumIcon } from '@shared/components/icons'

const animateRotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`

export function LoaderFullScreen() {
    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'rgba(0, 0, 0, 0.9)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
                zIndex: 20,
            }}
        >
            <LumIcon
                as="skylumLogoLoader"
                viewBox="0 0 80 80"
                size="80px"
                sx={{ animation: `${animateRotate} 1.5s linear infinite` }}
            />
            <Typography
                align="center"
                sx={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontWeight: 500,
                    mt: '22px',
                }}
            >
                Loading...
            </Typography>
        </Box>
    )
}
