import { selectors, store, actions } from '@features/auth/store'
import { IDs } from '@features/auth/testIds'
import { useForm } from '@features/toolsPanel/hooks/form'
import { ErrorOutlineRounded } from '@mui/icons-material'
import { Box, Button, InputAdornment, Link, Typography } from '@mui/material'
import { Input } from '@shared/components/input'
import { getTestId } from '@shared/testing'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { useSession } from 'next-auth/react'
import React, { useCallback, useEffect } from 'react'
import { FieldValues } from 'react-hook-form'
import { ErrorMessagesList, ErrorMessage } from '../ErrorMessages'
import { Loader } from '../Loader'
import { Password } from '../Password'
import { SocialAuth } from '../SocialAuth'
import { useServices } from '@shared/components/providers/services'
import { authState } from '@features/auth/store/store'

export const Login = () => {
    const {
        onSubmit,
        emailProps,
        isServerError,
        formErrors,
        formIsValid,
        passwordProps,
        switchToRegistering,
        isFetching,
        switchToForgotPassword,
    } = useLoginForm()
    const { analytics } = useServices()

    useEffect(() => {
        // ignore analytics event for testing
        if (process.env.NODE_ENV !== 'test') {
            analytics.showPopupAuthUsage('sign_in_popup_show')
        }
    }, [])

    return (
        <Box
            {...getTestId(IDs.login.form)}
            sx={{
                m: '0 auto',
                position: 'relative',
                width: '392px',
                background: 'rgba(55, 55, 55, 0.4)',
                backdropFilter: 'blur(72px)',
                borderRadius: theme => `${(theme.shape.borderRadius as number) * 2}px`,
                height: '670px',
            }}
        >
            <Box
                component={'form'}
                onSubmit={onSubmit}
                sx={{
                    display: 'flex',
                    minHeight: '480px',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '40px 40px 24px',
                    height: '100%',
                }}
            >
                <Box sx={{ flex: '2 0 auto' }}>
                    <Typography sx={{ mb: '8px', font: '700 28px/40px Inter' }} variant="h1">
                        Sign In
                    </Typography>
                    <Typography sx={{ mb: '27px', color: newTextColor }} variant="h3">
                        New user?{' '}
                        <Link href="#" component={'span'} onClick={switchToRegistering}>
                            Create an account
                        </Link>
                    </Typography>
                    <Typography sx={{ mb: '12px', font: '600 17px/24px Inter' }} variant="h2">
                        Sign In with
                    </Typography>
                    <Box sx={{ mb: '28px' }}>
                        <SocialAuth />
                    </Box>
                    <Typography sx={{ mb: '12px', font: '600 17px/24px Inter' }} variant="h2">
                        Sign In with email
                    </Typography>
                    <Input fullWidth id="email" placeholder="Email address" {...emailProps} />
                    {!formIsValid && <ErrorMessagesList errors={formErrors} type="email" />}
                    <Password fullWidth id="password" placeholder="Password" {...passwordProps} />
                    {!formIsValid && <ErrorMessagesList errors={formErrors} type="password" />}
                    {isServerError && <ErrorMessage>{'The email or password are incorrect'}</ErrorMessage>}
                    <Typography
                        sx={{ color: newTextColor }}
                        onClick={switchToForgotPassword}
                        variant="link"
                        component={'span'}
                    >
                        Forgot the password?
                    </Typography>
                </Box>
                <Box sx={{ flex: '0 1 auto' }}>
                    <Button type="submit" color="secondary" fullWidth size={'large'} sx={{ mb: '20px' }}>
                        {isFetching ? <Loader /> : 'Sign In'}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

const useLoginForm = () => {
    const { form } = useForm({ mode: 'onBlur' })
    const dispatch = useAppDispatch()
    const isServerError = useAppSelector(selectors.isLoginError)
    const isFetching = useAppSelector(selectors.isLoginProcessing)
    const { status } = useSession()
    const { analytics } = useServices()

    useEffect(() => {
        const sub = form.watch(() => {
            const { isDirty } = form.formState
            if (isDirty && isServerError) {
                dispatch(store.authState.actions.reset())
                form.clearErrors()
            }
        })
        return () => {
            sub.unsubscribe()
        }
    }, [dispatch, form, isServerError])

    useEffect(() => {
        if (isServerError) {
            form.setError('email', {})
            form.setError('password', {})
        }
    }, [isServerError, form])

    useEffect(() => {
        if (status === 'authenticated') {
            dispatch(actions.fetchUser.act())
        }
    }, [dispatch, status])

    const onSubmit = useCallback(
        (values: FieldValues) => {
            if (isFetching) return
            // ignore analytics event for testing
            if (process.env.NODE_ENV !== 'test') {
                dispatch(authState.actions.changeSocOrEmailType('email'))
            }

            dispatch(actions.login.act(values as { email: string; password: string }))

            // ignore analytics event for testing
            if (process.env.NODE_ENV !== 'test') {
                analytics.signInSubmitUsage()
            }
        },
        [analytics, dispatch, isFetching],
    )

    return {
        onSubmit: form.handleSubmit(onSubmit),
        switchToRegistering: () => {
            dispatch(store.authUIState.actions.changeAuth('registrationChoose'))

            // ignore analytics event for testing
            if (process.env.NODE_ENV !== 'test') {
                analytics.signInCreateAnAccountUsage()
            }
        },
        switchToForgotPassword: () => {
            dispatch(store.authUIState.actions.changeAuth('forgotPassword'))

            // ignore analytics event for testing
            if (process.env.NODE_ENV !== 'test') {
                analytics.signInForgotPasswordUsage()
            }
        },
        isFetching,
        isServerError,
        emailProps: {
            error: !!form.formState.errors?.email,
            ...form.register('email', {
                required: { value: true, message: 'Email address is required' },
                pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Entered value does not match email format',
                },
            }),
            endAdornment: form.formState.errors?.email ? (
                <InputAdornment position="end">
                    <ErrorOutlineRounded sx={{ color: 'accent.fourth' }} fontSize="small" />
                </InputAdornment>
            ) : null,
        },
        passwordProps: {
            error: !!form.formState.errors?.password,
            ...form.register('password', {
                required: { value: true, message: 'Password is required' },
            }),
            endAdornment: form.formState.errors?.password ? (
                <InputAdornment position="end">
                    <ErrorOutlineRounded sx={{ color: 'accent.fourth' }} fontSize="small" />
                </InputAdornment>
            ) : null,
        },
        formIsValid: form.formState.isValid,
        formErrors: form.formState.errors,
    }
}

const newTextColor = 'rgba(255, 255, 255, 0.7)'
