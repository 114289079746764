import { selectors, store, actions, uiSelectors } from '@features/auth/store'
import { IDs } from '@features/auth/testIds'
import { useForm } from '@features/toolsPanel/hooks/form'
import { ErrorOutlineRounded } from '@mui/icons-material'
import { Box, Button, InputAdornment, Typography } from '@mui/material'
import { getTestId } from '@shared/testing'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { throttle } from 'lodash-es'
import React, { useCallback, useEffect, useRef, useState, ClipboardEvent } from 'react'
import { FieldValues } from 'react-hook-form'
import { ErrorMessage, ErrorMessagesList } from '../ErrorMessages'
import { Loader } from '../Loader'
import { Password } from '../Password'
import {
    PasswordCheckPayload,
    passwordConditionsAreSatisfied,
    passwordRules,
    PasswordStrengthBlock,
} from '../PasswordStrengthBlock'
import { useServices } from '@shared/components/providers/services'

export const UpdatePassword = () => {
    const {
        onSubmit,
        isServerError,
        formErrors,
        passwordProps,
        repeatPasswordProps,
        isFetching,
        passwordList,
        forbidPaste,
    } = useUpdatePassword()
    const { analytics } = useServices()

    useEffect(() => {
        // ignore analytics event for testing
        if (process.env.NODE_ENV !== 'test') {
            analytics.showPopupAuthUsage('set_new_password_popup_show')
        }
    }, [])

    return (
        <Box
            sx={{
                m: '0 auto',
                position: 'relative',
                width: '392px',
                background: 'rgba(55, 55, 55, 0.4)',
                backdropFilter: 'blur(72px)',
                borderRadius: theme => `${(theme.shape.borderRadius as number) * 2}px`,
                height: '100%',
            }}
        >
            <Box
                {...getTestId(IDs.updatePassword.form)}
                component={'form'}
                onSubmit={onSubmit}
                autoComplete={'off'}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '40px 40px 24px',
                    height: '100%',
                }}
            >
                <Box sx={{ flex: '2 0 auto' }}>
                    <Typography sx={{ mb: '24px', font: '700 28px/40px Inter' }} variant="h1">
                        Enter New Password
                    </Typography>
                    {/* Hack for breaking chrome auto-fill */}
                    <input
                        type="password"
                        name="fake-password"
                        autoComplete="fake-password"
                        tabIndex={-1}
                        style={{ opacity: 0, float: 'left', border: 'none', height: 0, width: 0 }}
                    />
                    <Password
                        fullWidth
                        autoComplete="old-password"
                        id="password"
                        placeholder="Password"
                        sx={{ mb: '16px' }}
                        {...passwordProps}
                    />
                    <ErrorMessagesList errors={formErrors} type="password" />
                    <PasswordStrengthBlock checked={passwordList} />
                    <Password
                        fullWidth
                        id="repeatPassword"
                        autoComplete="new-password"
                        placeholder="Confirm password"
                        sx={{ mt: '16px' }}
                        inputProps={{ onPaste: forbidPaste }}
                        {...repeatPasswordProps}
                    />
                    <ErrorMessagesList errors={formErrors} type="repeatPassword" />
                    {isServerError && <ErrorMessage id="server-error">{'Something went wrong'}</ErrorMessage>}
                </Box>
                <Box sx={{ flex: '0 1 auto' }}>
                    <Button type="submit" color="secondary" fullWidth size={'large'} sx={{ mb: '20px' }}>
                        {isFetching ? <Loader /> : 'Set New Password'}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

const useUpdatePassword = () => {
    const { form } = useForm({ mode: 'onChange' })
    const dispatch = useAppDispatch()
    const [passCheck, setCheck] = useState<PasswordCheckPayload>({})
    const isServerError = useAppSelector(selectors.isUpdatePasswordError)
    const isFetching = useAppSelector(selectors.isUpdatePasswordProcessing)
    const currentEmail = useAppSelector(uiSelectors.getEmail)
    const passwordState = useRef<boolean>(false)
    const { analytics } = useServices()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateInfo = useCallback(
        throttle(() => {
            const values = form.getValues()
            const blurred = passwordState.current
            const result = passwordRules.reduce((acc, { id, validate }) => {
                const valid = validate(values.password || '', { email: currentEmail })
                return {
                    ...acc,
                    [id]: !valid && blurred ? 'error' : !valid && !blurred ? 'idle' : 'passed',
                }
            }, {})
            setCheck(result)
        }, 100),
        [form, currentEmail],
    )

    const passwordField = form.register('password', {
        required: { value: true, message: 'Password is required' },
        pattern: {
            value: /^[^ ]+$/,
            message: 'Password should`t contain spaces',
        },
        onBlur: () => {
            passwordState.current = true
            updateInfo()
        },
        onChange: () => {
            updateInfo()
        },
    })
    const repeatPasswordField = form.register('repeatPassword', {
        required: { value: true, message: 'Password is required' },
        validate: value => value === form.getValues()?.['password'] || 'This field needs to match the one above it',
    })

    useEffect(() => {
        const sub = form.watch(() => {
            const { isDirty } = form.formState
            if (isDirty && isServerError) {
                dispatch(store.authState.actions.resetActions('updatePassword'))
                form.clearErrors()
            }
        })
        return () => {
            sub.unsubscribe()
        }
    }, [dispatch, form, isServerError])

    useEffect(() => {
        if (isServerError) {
            form.setError('password', {})
            form.setError('repeatPassword', {})
        }
    }, [isServerError, form])

    const onSubmit = useCallback(
        (values: FieldValues) => {
            if (!passwordConditionsAreSatisfied(passCheck) || isFetching) return

            dispatch(actions.updatePassword.act({ password: values['repeatPassword'] }))

            // ignore analytics event for testing
            if (process.env.NODE_ENV !== 'test') {
                analytics.updatePasswordSubmitUsage()
            }
        },
        [dispatch, isFetching, passCheck],
    )

    return {
        onSubmit: form.handleSubmit(onSubmit),
        isFetching,
        isServerError,
        passwordProps: {
            error: !!form.formState.errors?.password,
            ...passwordField,
            endAdornment: form.formState.errors?.password ? (
                <InputAdornment position="end">
                    <ErrorOutlineRounded sx={{ color: 'accent.fourth' }} fontSize="small" />
                </InputAdornment>
            ) : null,
        },
        repeatPasswordProps: {
            error: !!form.formState.errors?.repeatPassword,
            ...repeatPasswordField,
            endAdornment: form.formState.errors?.repeatPassword ? (
                <InputAdornment position="end">
                    <ErrorOutlineRounded sx={{ color: 'accent.fourth' }} fontSize="small" />
                </InputAdornment>
            ) : null,
        },
        passwordList: passCheck,
        formErrors: form.formState.errors,
        forbidPaste: useCallback((event: ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            event.preventDefault()
            event.stopPropagation()
        }, []),
    }
}
