import React from 'react'
import { Dialog } from './components/Dialog'
import { Fabrik } from './components/Fabrik'
import { useAppSelector } from '@store/hooks'
import { uiSelectors } from '@features/auth/store'
import { AuthType } from './types'

type Props = {
    type: AuthType
}

export const Auth = (props: Props) => {
    return <Fabrik {...props} />
}

export const AuthPopup = () => {
    const type = useAppSelector(uiSelectors.getType)

    return (
        <Dialog>
            <Auth type={type} />
        </Dialog>
    )
}
