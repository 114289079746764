import { selectors, store, actions } from '@features/auth/store'
import { IDs } from '@features/auth/testIds'
import { useForm } from '@features/toolsPanel/hooks/form'
import { ErrorOutlineRounded } from '@mui/icons-material'
import { Box, Button, InputAdornment, Typography } from '@mui/material'
import { Input } from '@shared/components/input'
import { getTestId } from '@shared/testing'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import React, { useEffect } from 'react'
import { FieldValues } from 'react-hook-form'
import { isEmpty } from 'lodash-es'
import { ErrorMessagesList, ErrorMessage } from '../ErrorMessages'
import { Loader } from '../Loader'
import { useServices } from '@shared/components/providers/services'

const aFieldName = 'code'
export const PasswordResetCode = () => {
    const { onSubmit, aInputProp, isServerError, formErrors, isFetching } = usePasswordResetCode()
    const { analytics } = useServices()

    useEffect(() => {
        // ignore analytics event for testing
        if (process.env.NODE_ENV !== 'test') {
            analytics.showPopupAuthUsage('password_reset_code_popup_show')
        }
    }, [])

    return (
        <Box
            sx={{
                m: '0 auto',
                position: 'relative',
                width: '392px',
                background: 'rgba(55, 55, 55, 0.4)',
                backdropFilter: 'blur(72px)',
                borderRadius: theme => `${(theme.shape.borderRadius as number) * 2}px`,
            }}
        >
            <Box
                {...getTestId(IDs.passwordResetCode.form)}
                component={'form'}
                onSubmit={onSubmit}
                sx={{
                    display: 'flex',
                    minHeight: '448px',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '40px 40px 24px',
                    height: '100%',
                }}
            >
                <Box sx={{ flex: '2 0 auto' }}>
                    <Typography sx={{ mb: '16px', font: '700 28px/40px Inter' }} variant="h1">
                        Password Reset Code
                    </Typography>
                    <Typography sx={{ mb: '16px', color: newTextColor }} variant="h3">
                        Enter the code we have just sent to your email
                    </Typography>
                    <Input fullWidth placeholder="Password Reset Code" {...aInputProp} />
                    <ErrorMessagesList errors={formErrors} type={aFieldName} />
                    {isServerError && <ErrorMessage id="server-error">{'The reset code is incorrect'}</ErrorMessage>}
                </Box>
                <Box sx={{ flex: '0 1 auto' }}>
                    <Button type="submit" color="secondary" fullWidth size={'large'} sx={{ mb: '16px' }}>
                        {isFetching ? <Loader /> : 'Reset'}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

const usePasswordResetCode = () => {
    const { form } = useForm({ mode: 'onChange' })
    const dispatch = useAppDispatch()
    const isServerError = useAppSelector(selectors.isResetCodeError)
    const isFetching = useAppSelector(selectors.isResetCodeProcessing)
    const { analytics } = useServices()
    const field = form.register(aFieldName, {
        required: { value: true, message: 'Reset code is required' },
        pattern: {
            value: /^[^ ]+$/,
            message: 'Should have no whitespaces',
        },
    })

    const onSubmit = (values: FieldValues) => {
        if (isFetching || isEmpty(values)) return
        dispatch(actions.resetCode.act({ pin: values[aFieldName] as string }))

        // ignore analytics event for testing
        if (process.env.NODE_ENV !== 'test') {
            analytics.passwordResetCodeSubmitUsage()
        }
    }

    useEffect(() => {
        if (isServerError) {
            form.setError(aFieldName, {})
        }
    }, [isServerError, form])

    useEffect(() => {
        const sub = form.watch(() => {
            const { isDirty } = form.formState
            if (isDirty && isServerError) {
                dispatch(store.authState.actions.resetActions('resetCode'))
                form.clearErrors()
            }
        })
        return () => {
            sub.unsubscribe()
        }
    }, [dispatch, form, isServerError])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => form.clearErrors(), [])

    return {
        onSubmit: form.handleSubmit(onSubmit),
        isFetching,
        isResendFetching: useAppSelector(selectors.isResendProcessing),
        isServerError,
        aInputProp: {
            error: !!form.formState.errors?.[aFieldName],
            ...field,
            endAdornment: form.formState.errors?.[aFieldName] ? (
                <InputAdornment position="end">
                    <ErrorOutlineRounded sx={{ color: 'accent.fourth' }} fontSize="small" />
                </InputAdornment>
            ) : null,
        },
        formErrors: form.formState.errors,
    }
}

const newTextColor = 'rgba(255, 255, 255, 0.7)'
