import React, { ReactNode, useEffect, useState } from 'react'
import cls from 'classnames'
import classes from './index.module.scss'
import { Fade, Grid, useTheme } from '@mui/material'
import { Header } from '../header'
import Head from 'next/head'
import { useAppSelector } from '@store/hooks'
import { selectors } from '@features/renderer/store'
import { Box } from '@mui/system'
import { LoaderLuminar } from '@shared/components/loaderLuminar'
import { AuthPopup } from '@features/auth'
import { Dialog, Toast } from '@features/upload'
import { UnSupportBrowserPopup } from '@shared/components/unSupportBrowserPopup'
import { LoaderFullScreen } from '@shared/components/loaderFullScreen'
import { getLoading } from '@features/export/store/selectors'

type Props = {
    children?: ReactNode
    header?: ReactNode
    classes?: {
        root?: string
        head?: string
        main?: string
    }
}

export const Layout: React.FC<Props> = ({ children, classes: userClasses = {} }) => {
    const imageSrc = useAppSelector(selectors.getCurrentImage)
    const isExportLoader = useAppSelector(getLoading)
    const theme = useTheme()
    const [isSafari, setIsSafari] = useState(false)

    useEffect(() => {
        setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent))
    }, [])

    return (
        <>
            <Head>
                <meta name="viewport" content="initial-scale=1, width=device-width" />
                <title>Photo editor Skylum</title>
            </Head>
            <script
                type="module"
                async
                dangerouslySetInnerHTML={{
                    __html: `
                        import Luminar from '/wasm/mipl/mipl_web.js'

                        window.Luminar = Luminar
                `,
                }}
            />

            <Grid
                container
                alignItems={'stretch'}
                direction={'column'}
                sx={{ bgcolor: 'background.default', position: 'relative' }}
                component="div"
                className={cls(classes.root, userClasses.root)}
            >
                <Fade in={!!imageSrc} timeout={theme.transitions.duration.standard * 4}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            width: '100%',
                            height: '100%',
                            backgroundImage: `url(${imageSrc?.url})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            filter: 'blur(24px) brightness(0.25)',
                            zIndex: 0,
                        }}
                    ></Box>
                </Fade>

                <Grid
                    container
                    flex={'0 0 auto'}
                    sx={{ zIndex: 2 }}
                    component="header"
                    className={cls(userClasses.head)}
                >
                    <Header />
                </Grid>

                <Grid
                    container
                    flex={'1 0 auto'}
                    component="main"
                    sx={{ p: '0 10px', zIndex: 1 }}
                    className={cls(classes.lay, userClasses.head)}
                >
                    {children}
                </Grid>
                <LoaderLuminar />
                <AuthPopup />
                <Dialog />
                <Toast />
                {isSafari && <UnSupportBrowserPopup />}
                {isExportLoader && <LoaderFullScreen />}
            </Grid>
        </>
    )
}
