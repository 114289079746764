import { Box, Button, IconButton, Menu, MenuItem, SxProps, Theme, MenuProps } from '@mui/material'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { LumIcon, TYPES } from '../icons'
import { Payload } from './Header'
import scssVars from '@styles/vars.module.scss'
import { FileUpload } from '../fileUpload'
import { selectors as authSelectors, store as authStore, actions as authActions } from '@features/auth/store'
import { batch } from 'react-redux'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import { useState } from 'react'
import { actions, store } from '@features/export/store'
import { selectors, actions as rendererActions, selectors as rendererSelectors } from '@features/renderer/store'
import { editsSelectors } from '@features/toolsPanel/store'
import { ExportTypes } from '@features/export/types'
import { getTestId } from '@shared/testing'
import { IDs } from '@shared/testIds'
import { store as uploadStore } from '@features/upload/store'
import { signOut, useSession } from 'next-auth/react'
import { useServices } from '@shared/components/providers/services'

const { toggleOpen } = uploadStore.slice.actions
export const Left = () => {
    return <LumIcon viewBox="0 0 102 28" as="skylumLogoWhite" sx={{ fontSize: '102px', height: '28px' }} />
}

export const Center = () => {
    const dispatch = useAppDispatch()
    const fileAdded = useAppSelector(selectors.isImageInfoFetched)
    const editsExists = useAppSelector(editsSelectors.isEditsExists)
    const { analytics } = useServices()

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {editsExists && fileAdded ? (
                    <Button
                        {...getTestId(IDs.header.addNewPhoto.btn)}
                        startIcon={<LumIcon viewBox="0 0 28 28" as="addPhoto" sx={{ fontSize: '24px' }} />}
                        onClick={() => {
                            dispatch(toggleOpen(true))

                            // ignore analytics event for testing
                            if (process.env.NODE_ENV !== 'test') {
                                analytics.uploadNewImageUsage()
                            }
                        }}
                        sx={{
                            p: '2px 12px',
                            fontSize: '0.813rem',
                            borderRadius: '6px',
                            mr: '8px',
                            '.MuiButton-startIcon': {
                                mr: '4px',
                                '.MuiSvgIcon-root': {
                                    fontSize: '24px',
                                    color: 'inherit',
                                },
                            },
                        }}
                    >
                        Add image
                    </Button>
                ) : (
                    <>
                        <Button
                            startIcon={<LumIcon viewBox="0 0 28 28" as="addPhoto" sx={{ fontSize: '24px' }} />}
                            sx={{
                                p: '2px 12px',
                                fontSize: '0.813rem',
                                borderRadius: '6px',
                                mr: '8px',
                                cursor: 'pointer',
                                '.MuiButton-startIcon': {
                                    mr: '4px',
                                    '.MuiSvgIcon-root': {
                                        fontSize: '24px',
                                        color: 'inherit',
                                    },
                                },
                            }}
                            component={'label'}
                            htmlFor="icon-header-photo"
                            onClick={() => {
                                // ignore analytics event for testing
                                if (process.env.NODE_ENV !== 'test') {
                                    analytics.addImageUsage('fixed_bar')
                                }
                            }}
                        >
                            Add image
                        </Button>
                        <FileUpload
                            onChange={event => {
                                const result = (event.target as HTMLInputElement).files
                                result && dispatch(rendererActions.uploadImage.act([...result]))
                            }}
                            id="icon-header-photo"
                        />
                    </>
                )}
                <ExportMenu />
            </Box>
        </>
    )
}

export const Right = () => {
    const dispatch = useAppDispatch()
    const isLogged = useAppSelector(authSelectors.isLogged)
    const { changeAuth, setOpen } = authStore.authUIState.actions
    const { analytics } = useServices()

    const openAuth = () =>
        batch(async () => {
            dispatch(changeAuth('login'))
            dispatch(setOpen(true))

            // ignore analytics event for testing
            if (process.env.NODE_ENV !== 'test') {
                await analytics.signInOpenPopupUsage()
            }
        })

    return (
        <>
            {isLogged ? (
                <UserMenu />
            ) : (
                <Button
                    variant="text"
                    onClick={openAuth}
                    size={'small'}
                    sx={{
                        cursor: 'pointer',
                        color: 'text.secondary',
                        lineHeight: '28px',
                        fontSize: '13px',
                        p: '0',
                        mr: '5px',
                        '&:hover': {
                            color: 'text.primary',
                        },
                    }}
                >
                    Sign in
                </Button>
            )}
        </>
    )
}

const renderExportData: { type: ExportTypes; title: string; icon: keyof typeof TYPES }[] = [
    { type: 'jpeg', title: 'Save as JPEG', icon: 'imageJpeg' },
    { type: 'png', title: 'Save as PNG', icon: 'imagePng' },
]
const ExportMenu = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const dispatch = useAppDispatch()
    const fileAdded = useAppSelector(selectors.isImageInfoFetched)
    const isLogged = useAppSelector(authSelectors.isLogged)
    const { analytics } = useServices()
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)

        // ignore analytics event for testing
        if (process.env.NODE_ENV !== 'test') {
            analytics.saveCurrentImageUsage()
        }
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const exportImage = (type: ExportTypes) => () => {
        dispatch(store.slice.actions.setDesiredType(type))
        if (isLogged) {
            dispatch(store.slice.actions.setLoading(true))
        }
        dispatch(actions.handleExport())
        handleClose()
    }

    return (
        <>
            <Button
                {...getTestId(IDs.header.exportMenu.btn)}
                startIcon={<LumIcon viewBox="0 0 20 20" as="exportImageV2" sx={{ fontSize: '20px' }} />}
                onClick={handleClick}
                sx={[
                    {
                        p: '4px 12px',
                        fontSize: '0.813rem',
                        borderRadius: '6px',
                        '.MuiButton-startIcon': {
                            mr: '4px',
                        },
                        '.MuiSvgIcon-root': {
                            color: 'inherit',
                        },
                    },
                    !fileAdded && {
                        opacity: '.4',
                        pointerEvents: 'none',
                    },
                ]}
            >
                Export image
            </Button>
            <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {renderExportData.map(({ type, title, icon }) => (
                    <MenuItem key={type} sx={MenuItemSX} onClick={exportImage(type)}>
                        <LumIcon
                            color={'currentColor'}
                            viewBox="0 0 21 20"
                            as={icon}
                            sx={{ mr: '4px', fontSize: '21px' }}
                        />
                        {title}
                    </MenuItem>
                ))}
            </StyledMenu>
        </>
    )
}
const UserMenu = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const userMail = useAppSelector(authSelectors.getUserMail)
    const dispatch = useAppDispatch()
    const { status } = useSession()
    const { analytics } = useServices()
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const logoutClick = () => {
        if (status === 'authenticated') {
            signOut({ redirect: false })
        }
        dispatch(authActions.logout.act())
        handleClose()

        // ignore analytics event for testing
        if (process.env.NODE_ENV !== 'test') {
            analytics.logoutUsage()
        }
    }
    return (
        <>
            <IconButton
                {...getTestId(IDs.header.userMenu.btn)}
                disableFocusRipple
                sx={{ mr: '5px' }}
                onClick={handleClick}
            >
                <PersonOutlineRoundedIcon sx={{ fontSize: '20px' }} />
            </IconButton>
            <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem sx={{ ...MenuItemSX, mb: '4px' }}>{userMail}</MenuItem>
                <Box sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.12)', m: 0, p: 0 }} />
                <MenuItem sx={{ ...MenuItemSX, mt: '4px' }} onClick={logoutClick}>
                    Logout
                </MenuItem>
            </StyledMenu>
        </>
    )
}

const StyledMenu: React.FC<MenuProps> = props => {
    return (
        <Menu
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            elevation={1}
            sx={{
                mt: '4px',
                '& .MuiPaper-root': {
                    background: 'rgba(255, 255, 255, 0.12)',
                    border: '1px solid rgba(255, 255, 255, 0.12)',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    backdropFilter: 'blur(72px)',
                    '& .MuiList-root': {
                        padding: '8px',
                    },
                },
            }}
            {...props}
        />
    )
}

const MenuItemSX: SxProps<Theme> = {
    color: 'text.secondary',
    padding: '0 8px',
    lineHeight: '24px',
    borderRadius: theme => `${Number(theme.shape.borderRadius) / 2}px`,
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
    },
    '&:active': {
        color: 'text.primary',
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
    },
}
