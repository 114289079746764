import { Typography, Box } from '@mui/material'

type PasswordRules = {
    id: string
    message: string
    validate: (value: string, form: Record<string, any>) => boolean
}[]

const regLower = new RegExp(/(?=.*[a-z])(?=.*[A-Z])/)
const regNumber = new RegExp(/(?=.*\W)|(?=.*\d)/)
export const passwordRules: PasswordRules = [
    {
        id: '8characters',
        message: 'Contains at least 8 characters',
        validate: value => !!value && value.length >= 8,
    },
    {
        id: 'lowerUpper',
        message: 'Contains both lower (a-z) and upper case letters (A-Z)',
        validate: value => regLower.test(value),
    },
    {
        id: 'isNumber',
        message: 'Contains at least one number (0-9) or a symbol',
        validate: value => regNumber.test(value),
    },
    {
        id: 'notEmailAddress',
        message: 'Doesn`t contain your email address',
        validate: (value, form) => !value || !form.email || !value.includes(form.email),
    },
]

export type PasswordCheckPayload = Partial<Record<string, 'idle' | 'passed' | 'error'>>

export const PasswordStrengthBlock = ({ checked }: { checked: PasswordCheckPayload }) => {
    const getContent = (id: string) => {
        const result = checked[id]
        if (typeof result === 'undefined' || result === 'idle') {
            return '"•"'
        }

        return result === 'passed'
            ? `url('data:image/svg+xml,%3Csvg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cmask id="mask0_2244_210" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20"%3E%3Crect width="20" height="20" fill="%23D9D9D9"/%3E%3C/mask%3E%3Cg mask="url(%23mask0_2244_210)"%3E%3Cpath d="M8.438 11.208L13.333 6.333C13.5417 6.125 13.7813 6.021 14.052 6.021C14.3227 6.021 14.5623 6.125 14.771 6.333C14.979 6.54166 15.083 6.78133 15.083 7.052C15.083 7.32266 14.979 7.56233 14.771 7.771L9.167 13.396C8.95833 13.604 8.71167 13.708 8.427 13.708C8.14233 13.708 7.896 13.604 7.688 13.396L5.438 11.125C5.22933 10.9163 5.125 10.6767 5.125 10.406C5.125 10.1353 5.22933 9.896 5.438 9.688C5.646 9.47933 5.88533 9.375 6.156 9.375C6.42667 9.375 6.66633 9.47933 6.875 9.688L8.438 11.208Z" fill="%2332DF8F"/%3E%3C/g%3E%3C/svg%3E%0A')`
            : `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8.804" height="8.804" viewBox="0 0 8.804 8.804"%3E%3Cpath id="Shape" d="M9.074,7.962,6.014,4.9l3.06-3.06A.786.786,0,0,0,7.962.73L4.9,3.79,1.842.73A.786.786,0,1,0,.73,1.842L3.79,4.9.73,7.962A.786.786,0,0,0,1.842,9.074L4.9,6.014l3.06,3.06A.786.786,0,0,0,9.074,7.962Z" transform="translate(-0.5 -0.5)" fill="%23d7373f"/%3E%3C/svg%3E')`
    }
    return (
        <>
            <Typography sx={{ m: '0 0 8px' }} variant="subtitle2">
                Create a Password that:
            </Typography>
            <Box sx={{ listStyle: 'none', m: 0, p: 0 }} component={'ul'}>
                {passwordRules.map(({ message, id }) => (
                    <Typography
                        data-error-state={checked[id]}
                        sx={{
                            mb: '8px',
                            ml: '20px',
                            position: 'relative',
                            '&:before': {
                                content: getContent(id),
                                position: 'absolute',
                                top: 0,
                                left: -20,
                                display: 'inline-block',
                                textAlign: 'center',
                                verticalAlign: 'middle',
                                width: '20px',
                                height: '20px',
                            },
                        }}
                        key={id}
                        variant="subtitle2"
                        component={'li'}
                    >
                        {message}
                    </Typography>
                ))}
            </Box>
        </>
    )
}

export const passwordConditionsAreSatisfied = (payload: PasswordCheckPayload, rules = passwordRules): boolean => {
    const map = Object.values(payload)
    return map.length === rules.length && map.every(bool => bool === 'passed')
}
