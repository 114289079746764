export const IDs = {
    header: {
        userMenu: {
            btn: 'user-menu-btn',
        },
        exportMenu: {
            btn: 'export-menu-btn',
        },
        addNewPhoto: {
            btn: 'add-new-photo-menu-btn',
        },
    },
    main: {
        loader: 'lumi-loader',
        upload: {
            input: 'upload-input',
        },
    },
    banners: {
        tryLuminarNeo: {
            btn: 'try-luminar-neo-btn',
            block: 'try-luminar-neo-block',
        },
        tryLuminarNeoBig: {
            btn: 'try-luminar-neo-big-btn',
            block: 'try-luminar-neo-big-block',
        },
    },
} as const
