import { AuthType } from '@features/auth/types'
import React from 'react'
import { ForgotPassword } from '../ForgotPassword'
import { Login } from '../Login'
import { Registration } from '../Registration'
import { Verification } from '../Verification'
import { UpdatePassword } from '../UpdatePassword'
import { PasswordResetCode } from '../PasswordResetCode'
import { RegistrationOptions } from '../RegistrationOptions'

type Props = {
    type: AuthType
}

export const Fabrik = (props: Props) => {
    switch (props.type) {
        case 'login':
            return <Login />
        case 'registration':
            return <Registration />
        case 'verification':
            return <Verification />
        case 'forgotPassword':
            return <ForgotPassword />
        case 'resetCode':
            return <PasswordResetCode />
        case 'newPassword':
            return <UpdatePassword />
        case 'registrationChoose':
            return <RegistrationOptions />
        default:
            return null
    }
}
