import { selectors, actions } from '@features/auth/store'
import { useForm } from '@features/toolsPanel/hooks/form'
import { ErrorOutlineRounded } from '@mui/icons-material'
import { Box, Button, InputAdornment, Typography } from '@mui/material'
import { Input } from '@shared/components/input'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import React, { useCallback, useEffect } from 'react'
import { FieldValues } from 'react-hook-form'
import { ErrorMessagesList, ErrorMessage } from '../ErrorMessages'
import { Loader } from '../Loader'
import { useServices } from '@shared/components/providers/services'

const aFieldName = 'verify'
export const Verification = () => {
    const { onSubmit, aInputProp, isServerError, formErrors, isFetching, onResendCode, isResendFetching } =
        useVerification()
    const { analytics } = useServices()

    useEffect(() => {
        // ignore analytics event for testing
        if (process.env.NODE_ENV !== 'test') {
            analytics.showPopupAuthUsage('verification_popup_show')
        }
    }, [])

    return (
        <Box
            sx={{
                m: '0 auto',
                position: 'relative',
                width: '392px',
                background: 'rgba(55, 55, 55, 0.4)',
                backdropFilter: 'blur(72px)',
                borderRadius: theme => `${(theme.shape.borderRadius as number) * 2}px`,
            }}
        >
            <Box
                component={'form'}
                onSubmit={onSubmit}
                sx={{
                    display: 'flex',
                    minHeight: '448px',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '40px 40px 24px',
                    height: '100%',
                }}
            >
                <Box sx={{ flex: '2 0 auto' }}>
                    <Typography sx={{ mb: '8px', font: '700 28px/40px Inter' }} variant="h1">
                        Verify Account
                    </Typography>
                    <Typography sx={{ mb: '27px', color: newTextColor }} variant="h3">
                        Your account has been successfully created!
                        <br />
                        To complete registration verify your account.
                        <br />
                        Please follow the instructions we`ve sent you on email
                    </Typography>
                    <Input fullWidth id="verify" placeholder="Verification Code" {...aInputProp} />
                    <ErrorMessagesList errors={formErrors} type={aFieldName} />
                    {isServerError && <ErrorMessage>{'The verification code is incorrect'}</ErrorMessage>}
                </Box>
                <Box sx={{ flex: '0 1 auto' }}>
                    <Button type="submit" color="secondary" fullWidth size={'large'} sx={{ mb: '16px' }}>
                        {isFetching ? <Loader /> : 'Verify'}
                    </Button>
                    <Button onClick={onResendCode} type="button" variant="text" fullWidth size={'medium'}>
                        {isResendFetching ? <Loader /> : 'Resend verification code'}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

const useVerification = () => {
    const { form } = useForm({ mode: 'onChange' })
    const dispatch = useAppDispatch()
    const isServerError = useAppSelector(selectors.isConfirmEmailError)
    const userMail = useAppSelector(selectors.getUserMail)
    const isFetching = useAppSelector(selectors.isConfirmationEmailProcessing)
    const { analytics } = useServices()
    const field = form.register(aFieldName, {
        required: { value: true, message: 'Verification code is required' },
        pattern: {
            value: /^[^ ]+$/,
            message: 'Should have no whitespaces',
        },
        onChange: () => {
            if (!form.formState.isValid) return
            const values = form.getValues()
            const value: string = values[aFieldName] || ''
            if (value.length === 4) onSubmit(values)
        },
    })

    const onSubmit = useCallback(
        (values: FieldValues) => {
            if (isFetching) return
            dispatch(actions.confirmEmail.act({ email: userMail, code: values[aFieldName] }))

            // ignore analytics event for testing
            if (process.env.NODE_ENV !== 'test') {
                analytics.verificationSubmitUsage()
            }
        },
        [dispatch, isFetching, userMail],
    )

    useEffect(() => {
        return () => {
            form.reset()
        }
    }, [form])

    return {
        onSubmit: form.handleSubmit(onSubmit),
        isFetching,
        isResendFetching: useAppSelector(selectors.isResendProcessing),
        onResendCode: () => {
            dispatch(actions.resendCode.act({ email: userMail }))

            // ignore analytics event for testing
            if (process.env.NODE_ENV !== 'test') {
                analytics.verificationResendUsage()
            }
        },
        isServerError,
        aInputProp: {
            error: !!form.formState.errors?.[aFieldName],
            ...field,
            endAdornment: form.formState.errors?.[aFieldName] ? (
                <InputAdornment position="end">
                    <ErrorOutlineRounded sx={{ color: 'accent.fourth' }} fontSize="small" />
                </InputAdornment>
            ) : null,
        },
        formIsValid: form.formState.isValid,
        formErrors: form.formState.errors,
    }
}

const newTextColor = 'rgba(255, 255, 255, 0.7)'
