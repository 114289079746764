import { LumIcon } from '@shared/components/icons'

export const Loader = () => (
    <LumIcon
        as="authLoading"
        viewBox="0 0 24 24"
        sx={{
            animation: 'rotate 1s linear infinite',
            m: '2px 0',
        }}
    />
)
