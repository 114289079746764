import React from 'react'
import { Typography, Box } from '@mui/material'
import scssVars from '@styles/vars.module.scss'
import { LumIcon } from '@shared/components/icons'

export function UnSupportBrowserPopup() {
    return (
        <Box
            sx={[
                {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: scssVars.customPrimaryBackDarkColor,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    width: '100%',
                    zIndex: 20,
                },
            ]}
        >
            <Typography
                align="center"
                sx={{
                    fontSize: '36px',
                    lineHeight: '32px',
                    fontWeight: 600,
                    mb: '24px',
                }}
            >
                Oops!
            </Typography>
            <Typography
                align="center"
                sx={{
                    fontSize: '14px',
                    lineHeight: '24px',
                    fontWeight: 600,
                    mb: '28px',
                    color: '#C4C4C4',
                }}
            >
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Looks like your browser doesn't support this <br />
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                page's functionality. To continue viewing, please switch to
            </Typography>
            <LumIcon as="googleChrome" viewBox="0 0 62 62" size="62px" sx={{ mb: '28px' }} />
            <Typography
                align="center"
                sx={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontWeight: 500,
                }}
            >
                Google Chrome
            </Typography>
        </Box>
    )
}
