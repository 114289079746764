import { RemoveRedEyeRounded, VisibilityOffRounded } from '@mui/icons-material'
import { Box, IconButton, InputAdornment, InputProps } from '@mui/material'
import { Input } from '@shared/components/input'
import React, { forwardRef, useState } from 'react'

type Props = InputProps

type InputType = 'password' | 'text'

const icons: Record<InputType, React.ReactNode> = {
    text: <RemoveRedEyeRounded sx={{ color: 'text.secondary' }} fontSize="small" />,
    password: <VisibilityOffRounded sx={{ color: 'text.secondary' }} fontSize="small" />,
}

export const Password = forwardRef<HTMLInputElement, Props>(function Password({ endAdornment, ...props }, ref) {
    const [type, setType] = useState<InputType>('password')

    return (
        <Box sx={{ position: 'relative' }}>
            <Input
                ref={ref}
                id="password"
                type={type}
                endAdornment={
                    <>
                        <InputAdornment position="end">
                            <IconButton onClick={() => setType(type === 'password' ? 'text' : 'password')} edge="end">
                                {icons[type]}
                            </IconButton>
                        </InputAdornment>
                        {endAdornment}
                    </>
                }
                {...props}
            />
        </Box>
    )
})
