import { Box, List, ListItem, Typography, Button } from '@mui/material'
import React, { useCallback } from 'react'
import { useDropzone, FileWithPath } from 'react-dropzone'
import { uploadImage } from '@features/renderer/store/actions'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { FileUpload } from '@shared/components/fileUpload'
import SvgIcon from '@mui/material/SvgIcon'
import { selectors } from '@features/renderer/store'
import { SUPPORTED_IMAGE_FORMATS } from '@shared/constants'
import { getLoaderLuminar } from '@store/selectors.ui'
import { theme } from '@shared/components/providers'
import scssVars from '@styles/vars.module.scss'
import { BannerTryLuminarBig } from '@shared/components/bannerTryLuminarBig'
import { useServices } from '@shared/components/providers/services'

export * from './components/Dialog'
export * from './components/Toast'

export function Upload() {
    const isLoadingLoaderLuminar = useAppSelector(getLoaderLuminar)
    const fileAdded = useAppSelector(selectors.isImageInfoFetched)
    const dispatch = useAppDispatch()
    const onDrop = useCallback((acceptedFiles: File[]) => dispatch(uploadImage.act(acceptedFiles)), [dispatch])
    const { analytics } = useServices()

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop,
        accept: {
            [`image/jpeg`]: SUPPORTED_IMAGE_FORMATS.map(format => `.${format}`),
        },
    })

    const fileRejectionFile = fileRejections.map(({ file, errors }) => {
        return (
            <>
                <ListItem key={(file as FileWithPath).path} disablePadding={true}>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography>
                            {(file as FileWithPath).path} - {file.size} bytes
                        </Typography>
                        {errors.map(e => (
                            <Typography key={e.code} variant="body1" sx={{ color: theme.palette.error.main }}>
                                {e.message}
                            </Typography>
                        ))}
                    </Box>
                </ListItem>
            </>
        )
    })

    return (
        <Box
            sx={[
                {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    opacity: 0,
                    pointerEvents: 'none',
                    zIndex: 11,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    transform: 'scale(1.2)',
                    transition: `${theme.transitions.create(['opacity', 'transform', 'width'], {
                        duration: 600,
                        easing: theme.transitions.easing.easeOut,
                    })}`,
                },
                !isLoadingLoaderLuminar && {
                    opacity: 1,
                    width: 'calc(100% - 304px)',
                    transform: 'scale(1)',
                    pointerEvents: 'auto',
                },
                fileAdded && {
                    opacity: 0,
                    pointerEvents: 'none',
                },
            ]}
        >
            <Box
                sx={{
                    border: `2px dashed  ${theme.palette.primary.light}`,
                    width: '100%',
                    maxWidth: '776px',
                    height: '448px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    transition: 'box-shadow .1s',
                    cursor: 'pointer',
                    backgroundColor: scssVars.customSecondaryBackDarkColor,
                    borderRadius: '24px',
                    backdropFilter: 'blur(10px)',
                    WebkitBackdropFilter: 'blur(10px)',
                }}
                {...getRootProps({
                    onClick: () => {
                        // ignore analytics event for testing
                        if (process.env.NODE_ENV !== 'test') {
                            analytics.addImageUsage('upload_photo_block')
                        }
                    },
                })}
            >
                <SvgIcon
                    sx={{
                        width: '111px',
                        height: '108px',
                        fill: 'none',
                        marginBottom: '26px',
                    }}
                    viewBox="0 0 111 108"
                >
                    <g clipPath="url(#clip0_320_8999)">
                        <g filter="url(#filter0_b_320_8999)">
                            <path
                                d="M35.6904 46.3248L17.2754 64.5569V74.1126C17.2754 76.3878 17.2754 77.5254 17.7182 78.3944C18.1077 79.1588 18.7291 79.7803 19.4936 80.1698C20.3626 80.6126 21.5002 80.6126 23.7754 80.6126H71.2698C71.5729 80.6126 71.7245 80.6126 71.8752 80.6014C72.0091 80.5914 72.1425 80.5748 72.2748 80.5516C72.4236 80.5255 72.5706 80.4883 72.8645 80.4139L75.9254 79.6393L43.0478 46.3652C41.7674 45.0693 41.1271 44.4214 40.386 44.1759C39.734 43.9599 39.0304 43.9561 38.3761 44.1648C37.6323 44.4022 36.985 45.0431 35.6904 46.3248Z"
                                fill="#C4C4C4"
                                fillOpacity="0.7"
                            />
                            <path
                                d="M17.6816 74.1126V64.7264L35.9762 46.6135C36.6283 45.9679 37.1002 45.5011 37.5012 45.1642C37.8985 44.8303 38.1992 44.6477 38.4996 44.5519C39.0721 44.3692 39.6878 44.3726 40.2582 44.5615C40.5576 44.6607 40.8563 44.8465 41.2499 45.1848C41.6472 45.5261 42.1139 45.998 42.7589 46.6508L75.1373 79.4197L72.7648 80.0201C72.4654 80.0959 72.3352 80.1286 72.2046 80.1514C72.0856 80.1723 71.9656 80.1873 71.845 80.1962C71.7128 80.2061 71.5786 80.2063 71.2698 80.2063H23.7754C22.6311 80.2063 21.7973 80.206 21.1402 80.1523C20.4879 80.0991 20.0457 79.9952 19.678 79.8078C18.99 79.4573 18.4307 78.898 18.0801 78.21C17.8928 77.8423 17.7889 77.4001 17.7356 76.7478C17.682 76.0907 17.6816 75.2569 17.6816 74.1126Z"
                                stroke="white"
                                strokeOpacity="0.1"
                                strokeWidth="0.812501"
                                strokeLinecap="round"
                            />
                        </g>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M88.3984 67.8439L73.3874 53.1591L73.3874 53.1591C72.0992 51.8988 71.455 51.2687 70.7165 51.035C70.0668 50.8294 69.3689 50.8332 68.7215 51.0459C67.9857 51.2877 67.3485 51.9249 66.0741 53.1992L66.0741 53.1992L42.457 76.8164L42.4551 76.8128C40.0617 79.2062 37.0814 79.9981 35.9043 79.9981L38.0288 82.1708L41.2237 81.0346L41.3094 81.056C41.6002 81.1287 41.7455 81.1651 41.8927 81.1906C42.0235 81.2132 42.1554 81.2295 42.2878 81.2392C42.4368 81.2502 42.5866 81.2502 42.8863 81.2502H81.8984C84.1736 81.2502 85.3113 81.2502 86.1803 80.8074C86.9447 80.4179 87.5662 79.7964 87.9557 79.032C88.3984 78.163 88.3984 77.0254 88.3984 74.7502V67.8439Z"
                            fill="white"
                        />
                    </g>
                    <rect x="17" y="26" width="73" height="56" rx="7.175" stroke="white" strokeWidth="4" />
                    <g filter="url(#filter1_d_320_8999)">
                        <rect x="75" y="11" width="30" height="30" rx="15" fill="white" shapeRendering="crispEdges" />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M90 18C88.8954 18 88 18.8954 88 20V24H84C82.8954 24 82 24.8954 82 26C82 27.1046 82.8954 28 84 28H88V32C88 33.1046 88.8954 34 90 34C91.1046 34 92 33.1046 92 32V28H96C97.1046 28 98 27.1046 98 26C98 24.8954 97.1046 24 96 24H92V20C92 18.8954 91.1046 18 90 18Z"
                            fill="black"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_b_320_8999"
                            x="8.33788"
                            y="35.0735"
                            width="76.5254"
                            height="54.4766"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feGaussianBlur in="BackgroundImage" stdDeviation="4.46875" />
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_320_8999" />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_backgroundBlur_320_8999"
                                result="shape"
                            />
                        </filter>
                        <filter
                            id="filter1_d_320_8999"
                            x="69"
                            y="11"
                            width="42"
                            height="42"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="6" />
                            <feGaussianBlur stdDeviation="3" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.52 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_320_8999" />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_320_8999"
                                result="shape"
                            />
                        </filter>
                        <clipPath id="clip0_320_8999">
                            <rect x="19" y="28" width="69" height="52" rx="5.175" fill="white" />
                        </clipPath>
                    </defs>
                </SvgIcon>
                <Typography
                    variant="h2"
                    sx={{
                        marginBottom: '16px',
                    }}
                >
                    Drag and drop a photo here, or <b>Browse</b>
                </Typography>
                <Typography variant="body1" color={'text.secondary'}>
                    Supported format: <b>.{SUPPORTED_IMAGE_FORMATS.join(' .').toLocaleUpperCase()}</b>
                </Typography>
                {fileRejectionFile.length > 0 ? <List>{fileRejectionFile}</List> : ''}
            </Box>
            <FileUpload {...getInputProps({ multiple: false })} id="icon-button-photo" />
            <BannerTryLuminarBig />
        </Box>
    )
}
