import { Box, Typography } from '@mui/material'
import { DeepRequired, FieldErrorsImpl, FieldValues } from 'react-hook-form'

export const ErrorMessagesList: React.FC<{
    errors: FieldErrorsImpl<DeepRequired<FieldValues>>
    type: string
    map?: Record<string, JSX.Element>
}> = ({ errors = {}, type, map = {} }) => {
    const error = errors[type]
    if (!error) return null
    const child = (error.message as unknown as string) || map[error?.type as unknown as string]
    return child ? <ErrorMessage id={type}>{child}</ErrorMessage> : null
}

export const ErrorMessage = ({ children, id }: { children?: React.ReactNode; id?: string }) => (
    <Box sx={{ display: 'flex', mb: '10px' }}>
        <Typography data-testid={`error-field-${id}`} variant="body1" sx={{ color: newTextColor }}>
            {children}
        </Typography>
    </Box>
)

const newTextColor = 'rgba(255, 255, 255, 0.7)'
